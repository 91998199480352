const typographyPlugin = require('tailwind-utils/typography.plugin');

const typographyConfig = require('./configs/tailwind.typography');
const colorsConfig = require('./configs/tailwind.colors');
const proseConfig = require('./configs/tailwind.prose');

const fontFamily = {
  primary: `var(--font-primary), Arial, sans-serif`,
  secondary: `var(--font-secondary), Arial, sans-serif`,
  tertiary: `var(--font-tertiary), Arial, sans-serif`,
};

/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      fontFamily,
      colors: colorsConfig,

      typography: proseConfig(typographyConfig),
    },
  },

  safelist: ['bg-white', 'bg-black', 'bg-light-sand-500', 'text-black', 'text-white', 'text-gray-800'],

  plugins: [typographyPlugin(typographyConfig)],
};
